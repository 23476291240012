﻿import * as $ from 'jquery';

window.jQuery = $;
window.$ = $;
window.jquery = $;

/*require("jquery");
window.$ = window.jQuery = $;
*/
require("../../lib/kendo-ui/2024.1.319/js/kendo.all");
require("../../lib/kendo-ui/2024.1.319/js/kendo.aspnetmvc");