require('./kendo.web.js');
require('./kendo.dataviz.js');
require('./kendo.mobile.js');
require('./kendo.drawing.js');
require('./kendo.dom.js');

"bundle all";
var kendo$1 = kendo;

module.exports = kendo$1;
